import React from 'react';
import Layout from '../../components/layout';
import { Link, graphql } from 'gatsby';
import styles from './index.module.scss';
import HeroImageTakeover from '../../components/hero-image/hero-image-takeover';

const TpsLandingPageApplicationSuccess = ({ data }) => {
  return (
    <Layout footerComponent={null} headerComponent={null} hideContactBanner>
      <HeroImageTakeover fluid={data.file.childImageSharp.fluid}>
        <div className={`${styles.placementbox}`}>
          <div className={`${styles.placement}`}>
            <div className={`has-text-centered container ${styles.placement}`}>
              <div className={`${styles.thumbsup}`}>
                <h1
                  className={`title is-3 is-spaced ${styles.mainTitle} ${styles.heroHeading}`}
                >
                  Thank you for Applying!
                </h1>

                <p className={`content`}>
                  A message has been sent to your email address with information
                  about the next steps in the process.
                </p>

                <p className={`content`}>
                  A Trustaff career specialist will be in touch shortly.
                </p>
                <Link
                  className={`button is-info`}
                  to="https://www.cincinnatiperdiem.com/"
                  id=""
                >
                  Home
                </Link>
                <img
                  src="//www.jobs2careers.com/conversion2.php?p=2196"
                  width="1"
                  height="1"
                  alt=" "
                  border="0"
                  className={`is-hidden`}
                />
              </div>
            </div>
          </div>
        </div>
      </HeroImageTakeover>
    </Layout>
  );
};

export default TpsLandingPageApplicationSuccess;

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "application-success/success_thanks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
